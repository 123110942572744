import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .get('module/transaction', {
            params: userData
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`module/transaction/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('module/transaction', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    bulkaddtransaction(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('module/transaction/bulkaddupload', userData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    editData(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`module/transaction/${userData.id}`,
            userData
          )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`module/transaction/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    downloadDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/transaction/download/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDocument(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`transaction/filedocument/${id}`, {
            responseType: 'blob'
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deletefileData(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .delete('/transaction/filedocument/+${id}')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetcheexpense(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/expense/getalldata?type='+queryParams.type)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchexpencesub(ctx, {
      id
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`expensesub/${id}/getalldata`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // Transactiontra(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/tran/getalldata')
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },


    fetchbank(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/financialassets_bank/relateddata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchwcash(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/financialassets_cash/relateddata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchwallets(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/financialassets_wallet/relateddata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchpaylater(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/financialassets_paylater/relateddata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    transactiondata(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/transaction/relateddata')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
